"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store";

let config = {
  baseURL: process.env.VUE_APP_API_URL || "http://localhost",
  withCredentials: false
};

let configRoutingRules = {
  baseURL: process.env.VUE_APP_ROUTING_RULES_API || "http://localhost:8080",
  withCredentials: false
};

let configContractService = {
  baseURL: process.env.VUE_APP_CONTRACT_SERVICE_API
};

let configOrganisationService = {
  baseURL: process.env.VUE_APP_ORGANISATION_SERVICE_API
};

const _axios = axios.create(config);

const _axiosRoutingRules = axios.create(configRoutingRules);

const _axiosContractService = axios.create(configContractService);

const _axiosOrganisationService = axios.create(configOrganisationService);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch("application/logout");
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(_Vue) {
  _Vue.$axios = _axios;
  _Vue.$axiosRoutingRules = _axiosRoutingRules;
  _Vue.$axiosContractService = _axiosContractService;
  _Vue.$axiosOrganisationService = _axiosOrganisationService;

  window.axios = _axios;
  window.axiosRoutingRules = _axiosRoutingRules;
  window.axiosContractService = _axiosContractService;
  window.axiosOrganisationService = _axiosOrganisationService;

  Object.defineProperties(_Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    axiosRoutingRules: {
      get() {
        return _axiosRoutingRules;
      }
    },
    axiosContractService: {
      get() {
        return _axiosContractService;
      }
    },
    axiosOrganisationService: {
      get() {
        return _axiosOrganisationService;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
    $axiosRoutingRules: {
      get() {
        return _axiosRoutingRules;
      }
    },
    $axiosContractService: {
      get() {
        return _axiosContractService;
      }
    },
    $axiosOrganisationService: {
      get() {
        return _axiosOrganisationService;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
