<template>
  <div class="w-full">
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4">
        <p>
          <n-html :text="$t('insurance.new.item.model.label')" />
        </p>
      </div>
      <div class="col-span-12 md:col-span-8">
        <n-form-group>
          <n-form-input-default
            :placeholder="$t('insurance.new.item.model.placeholder')"
            v-model="model"
            :state="this.errors.model"
          />
        </n-form-group>
      </div>
    </div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4">
        <p>
          <n-html :text="$t('insurance.new.item.retail_price.label')" />
        </p>
      </div>
      <div class="col-span-12 md:col-span-8">
        <n-form-group>
          <n-form-input-default
            :placeholder="
              $t('insurance.new.item-details.retail_price.placeholder')
            "
            type="number"
            v-model="retail_price"
            :state="this.errors.retail_price"
          />
        </n-form-group>
      </div>
    </div>
    <div>
      <n-button-regular
        class="float-right"
        @click.native="confirm"
        :disabled="!isValid"
      >
        <n-html :text="$t('insurance.new.item.confirm')" />
      </n-button-regular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "components.insurances.new.step-item",
  props: {
    category: Object,
    manufacturer: Object,
    customerTemplate: String,
    contractAmount: Number
  },
  data: () => ({
    model: "",
    retail_price: 0,
    item: {
      item: null,
      model: "",
      retail_price: 0
    },
    errors: {
      retail_price: null
    }
  }),
  computed: {
    ...mapGetters({
      items: "insurance/getItems"
    }),
    isValid() {
      return (
        this.validModel(this.item.model) &&
        this.validRetailPrice(this.item.retail_price)
      );
    }
  },
  methods: {
    validModel(model) {
      const isValid = model !== "";
      this.errors.model = isValid;
      return isValid;
    },
    validRetailPrice(retailPrice) {
      const isValid = retailPrice > 0;
      this.errors.retail_price = isValid;
      return isValid;
    },
    confirm() {
      if (
        this.validRetailPrice(this.item.retail_price) &&
        this.validModel(this.item.model)
      ) {
        this.$parent.$parent.$emit("dataChange", this.item);
        this.$parent.$parent.$emit("pickedChange", this.item.model);
        this.$parent.$parent.setCollapsed(true);
      }
    }
  },
  watch: {
    manufacturer() {
      if (this.category.code && this.manufacturer.code) {
        this.$store
          .dispatch("insurance/getItems", {
            category: this.category.code,
            manufacturer: this.manufacturer.code,
            template: this.customerTemplate,
            contract_amount: this.contractAmount
          })
          .then(() => {
            if (this.items && this.items.length > 0) {
              this.item.item = this.items[0];
            }
          });
      }
    },
    model(newModel) {
      this.validModel(newModel);
      this.item.model = newModel;
    },
    retail_price(newRetailPrice) {
      this.validRetailPrice(newRetailPrice);
      this.item.retail_price = newRetailPrice;
    }
  },
  created() {
    // this.$store.dispatch("insurance/getItems", {
    //   category: this.category.code,
    //   manufacturer: this.manufacturer.code
    // });
  },
  mounted() {
    if (this.items && this.items.length > 0) {
      this.item.item = this.items[0];
    }
  }
};
</script>
